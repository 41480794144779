/* --- Global --- */
import {Router} from '@reach/router';
/* --- Local --- */
import {UserList, UserListFunded, UserListUnfunded} from '@components';

/* --- Component --- */
const PageHome = props => {
  return (
    <Router primary={false}>
      <Main path="/dashboard/requests" />
      <Funded path="/dashboard/funded" />
    </Router>
  );
};

const Main = props => {
  return (
    <Atom.Box sx={{p: 4}}>
      <Atom.Heading xxl>Funding Requests</Atom.Heading>
      <UserListUnfunded fundedStatus={false} />
    </Atom.Box>
  );
};

const Funded = props => {
  return (
    <Atom.Box sx={{p: 4}}>
      <Atom.Heading xxl>Funding Complete</Atom.Heading>
      <UserListFunded fundedStatus={true} />
    </Atom.Box>
  );
};
export default PageHome;
